
import { computed, defineComponent, reactive, onMounted, ref, watch } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { statusListAgency } from '@/utils/constants'
import useCatalogRepositories from '@/repositories/useCatalogRepositories'
import FilterArea from './components/FilterArea.vue'
import ItemCard from './components/ItemCard.vue'
import Pagination from '@/components/common/Pagination.vue'
import { useRoute, useRouter } from 'vue-router'
import PageTitle from '@/components/common/PageTitle.vue'
import usePublicCatalogRepositories from '@/repositories/usePublicCatalogRepositories'
import { useNotification } from '@/composables'
import CatalogFromUrlDialog from './components/CatalogFromUrlDialog.vue'
import { Download, UploadFilled } from '@element-plus/icons'
import { ElMessageBox } from 'element-plus'
import userProductRepositories from '@/repositories/userProductRepositories'
import ImportSupplierCodeDialog from '@/pages/agency/catalog/components/ImportSupplierCodeDialog.vue'
import { AuthorityEnum } from '@/utils/userUtils'
import HistoryDialog from '@/pages/agency/catalog/components/HistoryDialog.vue'

export default defineComponent({
  components: {
    HistoryDialog,
    ImportSupplierCodeDialog,
    sectionLayoutContent,
    FilterArea,
    ItemCard,
    Pagination,
    PageTitle,
    CatalogFromUrlDialog,
    Download,
    UploadFilled,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()

    const loading = ref(false)

    const breadcrumbs = ['Catalog', '']
    const titleCreate = 'Create Catalog'
    const titleImport = 'Create Catalog Dropship'

    const allowCreateCatalog = ref(false)

    const content = computed(() => {
      let contentTemp = {}
      if (allowCreateCatalog.value) {
        contentTemp = {
          breadcrumbs,
          titleCreate,
          titleImport,
        }
      } else {
        contentTemp = {
          breadcrumbs,
        }
      }
      return contentTemp
    })
    const resource = `/catalogs`

    const params = reactive({
      search: route.query.search || null,
      categories: route.query.categories || null,
      status: route.query.status || null,
      partners: route.query.partners || null,
      locations: route.query.locations || null,
      techniques: route.query.techniques || null,
      printAreas: route.query.printAreas || null,
      type: route.query.type || null,
      page: route.query.page || 1,
      limit: route.query.limit || 20,
    })
    const total = ref(0)

    const { getCatalogs, checkAllowCreateCatalog } = useCatalogRepositories()

    const catalogs = ref<any>([])
    const getData = async () => {
      loading.value = true
      const res = (await getCatalogs(params)) as any
      catalogs.value = res.data.data
      total.value = res.data.pagination.total
      loading.value = false
    }

    const checkAllowCreateCatalogs = async () => {
      const res = await checkAllowCreateCatalog()
      allowCreateCatalog.value = res?.allowCreateCatalog
    }

    const create = () => {
      router.push({ name: 'ag.catalog-create' })
    }

    const createDropship = () => {
      router.push({ name: 'ag.catalog-create.dropship' })
    }

    const catalogFromUrlDialog =
      ref<InstanceType<typeof CatalogFromUrlDialog>>()
    const onCreateByUrl = () => {
      catalogFromUrlDialog.value?.toggle()
    }

    const importSupplierCodeDialog =
      ref<InstanceType<typeof ImportSupplierCodeDialog>>()
    const onImportSupplierCodes = () => {
      importSupplierCodeDialog.value?.toggle()
    }

    const historyDialog = ref<InstanceType<typeof HistoryDialog>>()
    const openHistoryDialog = () => {
      historyDialog.value?.toggle()
    }

    const updateURL = () => {
      router.push({
        query: params,
      })
    }

    const isAccounting = computed(() => {
      const role = JSON.parse(localStorage.getItem('info') || '')?.authority
      if (role === AuthorityEnum.Accounting) {
        return true
      }
      return false
    })

    onMounted(() => {
      getData()
      checkAllowCreateCatalogs()
    })

    watch(
      params,
      () => {
        updateURL()
        getData()
      },
      { deep: true }
    )
    const { clearCachePublicCatalog } = usePublicCatalogRepositories()
    const { success, error } = useNotification()

    const onClearCachePublicCatalog = async () => {
      const res: { status: number } = await clearCachePublicCatalog()
      if (res?.status === 200) {
        success('Clear cache success!')
      } else {
        error('Clear error!')
      }
    }

    const downloadFile = (fileName: string, urlData: any) => {
      var aLink = document.createElement('a')
      aLink.download = fileName
      aLink.href = urlData
      var event = new MouseEvent('click')
      aLink.dispatchEvent(event)
    }

    const { exportCatalogs } = userProductRepositories()
    const showModalExport = async (type: string) => {
      try {
        ElMessageBox.confirm(
          `Export all ${type} catalogs. Confirm this action??`,
          'Export Agency Catalogs',
          {
            confirmButtonText: 'Export',
            cancelButtonText: 'Cancel',
            confirmButtonClass: 'primary',
            type: 'warning',
          }
        )
          .then(async () => {
            const res = (await exportCatalogs(type)) as any
            if (res?.data?.url) {
              downloadFile('export.csv', res?.data?.url)
            } else {
              error('Error when export file')
            }
          })
          .catch(e => {
            console.log(e)
          })
      } catch (e) {
        error('Export order error!')
      }
    }

    const clearFilters = () => {
      params.search = null
      params.categories = null
      params.status = null
      params.partners = null
      params.locations = null
      params.techniques = null
      params.printAreas = null
      params.type = null
      params.page = 1
    }

    return {
      content,
      resource,
      catalogs,
      total,
      params,
      getData,
      statusListAgency,
      clearFilters,
      onClearCachePublicCatalog,
      create,
      createDropship,
      onCreateByUrl,
      catalogFromUrlDialog,
      showModalExport,
      onImportSupplierCodes,
      importSupplierCodeDialog,
      isAccounting,
      historyDialog,
      openHistoryDialog,
    }
  },
})
