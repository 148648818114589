
import { defineComponent, onMounted, ref } from 'vue'
import { imageUrl } from '@/utils/image'
import { isManagerOrAccounting } from '@/utils/orderUtils'
import useCatalogRepositories from '@/repositories/useCatalogRepositories'

export default defineComponent({
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const isManager = ref<boolean>(false)
    const allowCreateCatalog = ref(false)
    const { checkAllowCreateCatalog } = useCatalogRepositories()

    onMounted(async() => {
      const userInfo = JSON.parse(localStorage?.getItem('info') || '')
      isManager.value = isManagerOrAccounting(userInfo)
      await checkAllowCreateCatalogs()
    })

    const checkAllowCreateCatalogs = async () => {
      const res = await checkAllowCreateCatalog();
      allowCreateCatalog.value = res?.allowCreateCatalog;
    }

    const redirectLink = (product: any) => {
      if (isManager.value || allowCreateCatalog.value === true) {
        return `/ag/base-cost/catalogs/${product._id}`
      }
      return `/s/catalogs/detail/${product._id}`
    }

    return {
      imageUrl,
      redirectLink,
    }
  },
})
