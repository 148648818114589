import { useResource } from '@/services/useResource'

export default function usePublicCatalogRepositories() {
  const resource = `public/catalogs`
  // const resource = `http://localhost:3003/api/public/catalogs`

  const clearCachePublicCatalog = async () => {
    const { patch } = useResource(`${resource}/fulfillment/clear-cache`)

    return await patch({})
  }

  return { clearCachePublicCatalog }
}
