
import { defineComponent, reactive, ref, watch } from 'vue'
import { useFetchData } from '@/composables'
import { downloadFile } from '@/utils/file'
import { formatTime } from '@/utils/dateTime'
import { getFullUrlLattePrint } from '@/utils/image'
import Pagination from '@/components/common/Pagination.vue'
import FilterTable from '@/components/common/FilterTable.vue'
import moment from 'moment/moment'
import { View, Download } from '@element-plus/icons'
import { useRouter } from 'vue-router'
import DetailImportSupplierCode from '@/pages/agency/catalog/components/DetailImportSupplierCode.vue'
export default defineComponent({
  components: {
    DetailImportSupplierCode,
    Pagination,
    FilterTable,
    View,
    Download,
  },
  setup() {
    const router = useRouter()
    let dialogVisible = ref(false)
    const isShowTemplate = ref(true)
    const baseResource = 'import/history'
    let loading = ref(false)
    const params = reactive({
      search: '',
      startDate: '',
      endDate: '',
      timezone: '',
      importType: 'IMPORT_SUPPLIER_CODES',
      limit: 10,
      page: 1,
    })
    const data = reactive({
      daterange: '',
    })
    const seeDetail = ref(false)

    const { documents, fetchData, isLoading, total } = useFetchData(
      baseResource,
      params
    )
    loading = isLoading
    const toggle = () => {
      fetchData()
      dialogVisible.value = !dialogVisible.value
    }
    const close = () => {
      dialogVisible.value = false
    }

    const onFilterCallback = (data: any) => {
      params.search = data?.search
    }

    watch(params, fetchData)

    const onChangeDate = () => {
      if (data.daterange?.length > 1) {
        params.startDate = moment(data.daterange[0]).format('YYYY-MM-DD')
        params.endDate = moment(data.daterange[1]).format('YYYY-MM-DD')
        params.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      } else {
        params.startDate = ''
        params.endDate = ''
        params.timezone = ''
      }
    }

    const detailImportSupplierCode =
      ref<InstanceType<typeof DetailImportSupplierCode>>()
    const openDetailImportSupplierDialog = (id: string) => {
      seeDetail.value = true
      detailImportSupplierCode.value?.toggle(id)
    }

    return {
      isShowTemplate,
      toggle,
      dialogVisible,
      close,
      downloadFile,
      isLoading,
      documents,
      formatTime,
      getFullUrlLattePrint,
      total,
      params,
      onFilterCallback,
      data,
      onChangeDate,
      loading,
      detailImportSupplierCode,
      openDetailImportSupplierDialog,
      seeDetail,
    }
  },
})
